<template lang="pug">
  .search-wrapper(@click="toggleSearchField" :class="{ 'not-auth': !isAuthUser }")
    .search-wrapper__field
      .btn-search

      input#searchField.search__field(type="text" v-model="searchString" autocomplete="off"
        :placeholder="$t('headerNew.searchPlaceholder')" @input="keypressSearch")

      .fast-search-results-wrapper(v-if="fastSearchResults && fastSearchResults.count > 0")
        .head__close(@click="clearSearchStr")
          CrossShadowOffSearch(:isClickedCross="isClickedCross" color="#0188cd")

        .fast-search-results
          .results(v-for="(result) in fastSearchResults.data" :class="{ 'single': singleFastResults }")
            .result-img-wrapper
              img.result-image(:src="`game-icons/${result.image_file}`")
            .result-content-wrapper
              .result__title {{ result.title_en }}
              .search-btn-play(@click="handleSearchBtn(result)") {{ getTitleForMoney }}
</template>

<script>

const CrossShadowOffSearch = () => import('@/components/serviceComponents/svg/CrossShadowOffSearch');

import Search from './Search.vue';

export default {
  extends: Search,
  components: {
    CrossShadowOffSearch,
  },
};
</script>

<style lang="scss">
@import '@/assets/styles/serviceComponents/search_sw.scss';
</style>